import React from 'react'
import SingleJobDetails from '../../components/common/SingleJob/SingleJobDetails'

const DeveloperSingleJob = () => {
  return (
    <>
    <SingleJobDetails/>
    </>
  )
}

export default DeveloperSingleJob