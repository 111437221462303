export const steps = [
    {
      selector: '.first-step',
      content: 'This is the first step!',
    },
    {
      selector: '.second-step',
      content: 'This is the second step!',
    },
    // Add more steps as needed
  ];