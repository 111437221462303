import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { generateApiUrl } from '../../helper/utlis';
import clientInstance from '../../services/client.instance';
import { setSmallLoader } from './vendorDataSlice';

const initialAdminData = {
    screenLoader: false,
    smallLoader: false,
    listOfClients: [],
    assignedDeveloper: [],
    profileData: {},
    jobListing: [],
    singleJobListing: {},
    allApplications: {},
    adminTimeReportingList: [],
    engagement: {},
    adminApproveReject: [],
    suggestedDeveloper: [],
    adminDashboard: [],
    approvedLoader: false,
    notificationList: [],
    singleJobPagination: {},
    singleClient: {},
    accountDeletionList:{},
    adminClientList : [],
    timeReportDetails : {},
    invoiceDetails:{},
    developerTimeReport:[],
    invoiceTotalPage : null,
    timeReportingDetailTotalPage:null,
    configDetails: []
}

export const adminDataSlice = createSlice({
    name: 'adminData',
    initialState: initialAdminData,
    reducers: {

        setScreenLoader: (state, action) => {
            state.screenLoader = true;
        },
        setBtnLoader: (state, action) => {
            state.smallLoader = true;
        },
        setSuccessAdminData: (state, action) => {
            state.smallLoader = false;
            state.screenLoader = false;
        },

        setSuccessApplicationList: (state, action) => {
            state.smallLoader = false;
            state.screenLoader = false;
            state.allApplications = action.payload;
        },
        setSuccessProfileData: (state, action) => {
            state.smallLoader = false;
            state.screenLoader = false;
            state.profileData = action.payload
        },
        setSuccessAdminListClient: (state, action) => {
            state.smallLoader = false;
            state.listOfClients = action.payload
            state.screenLoader = false;
        },
        setSuccessAdminAssignedDeveloper: (state, action) => {
            state.smallLoader = false;
            state.screenLoader = false;
            state.assignedDeveloper = action.payload
        },
        setSuccessAdminJobListing: (state, action) => {
            state.smallLoader = false;
            state.jobListing = action.payload;
            state.screenLoader = false;
        },
        setSingleJobListing: (state, action) => {
            state.singleJobListing = action.payload
            state.screenLoader = false
        },

        setFailAdminData: (state, action) => {
            state.smallLoader = false;
            state.screenLoader = false;
        },
        setAdminTimeReporting: (state, action) => {
            state.adminTimeReportingList = action.payload
            state.smallLoader = false;
            state.screenLoader = false;
        },
        setAdminEngagment: (state, action) => {
            state.engagement = action.payload
            state.screenLoader = false;
        },
        setApproveReject: (state, action) => {
            state.adminApproveReject = action.payload;
            state.smallLoader = false;
            state.screenLoader = false;
            state.approvedLoader = false;
        },
        setApprovedLoader: (state, action) => {
            state.approvedLoader = true;
        },
        setSuggestedDeveloper: (state, action) => {
            let recomnd = action.payload?.recommended_developers?.map((item) => { return { ...item, recommed: true } })
            let data = [...recomnd, ...action.payload?.other_developers]
            state.suggestedDeveloper = data
            state.screenLoader = false
        },

        setAdminDashboard: (state, action) => {
            state.adminDashboard = action.payload
            state.smallLoader = false;
            state.screenLoader = false;
        },

        setNotificationList: (state, action) => {
            state.notificationList = action.payload.data
            state.smallLoader = false;
            state.screenLoader = false;
        },
        setPagination: (state, action) => {
            state.singleJobPagination = action.payload
        },
        setSingleClient: (state, action) => {
            state.screenLoader = false;
            state.singleClient = action.payload
        },
        setAccountEnableDisable:(state, action) =>{
            state.screenLoader = false;
            state.accountDeletionList = action.payload
        },
        setAdminClientList:(state ,action) => {
            state.screenLoader = false;
            state.adminClientList = action.payload
        },
        setTimeReportDetails : (state,action) => {
            state.screenLoader = false;
            state.timeReportDetails = action.payload
            state.timeReportingDetailTotalPage = action?.payload?.pagination?.total_pages
        },
        setInvoiceDetails :(state,action) => {
         state.screenLoader = false;
         state.invoiceDetails = action.payload
         state.invoiceTotalPage = action?.payload?.pagination?.total_pages
        },
        setDeveloperTimeReport:(state,action) => {
         state.smallLoader = false;
         state.developerTimeReport = action.payload;
        },
        setConfigDetails : (state,action) => {
         state.configDetails = action.payload
        },
    }
})

export const { setTimeReportDetails,setConfigDetails ,setDeveloperTimeReport,setInvoiceDetails , setSuggestedDeveloper,setAccountEnableDisable ,setAdminClientList , setSingleClient, setPagination, setNotificationList, setScreenLoader, setApprovedLoader, setAdminDashboard, setApproveReject, setAdminEngagment, setSingleJobListing, setAdminTimeReporting, setSuccessApplicationList, setFailAdminData, setSuccessAdminData, setSuccessProfileData, setSuccessAdminJobListing, setSuccessAdminListClient, setSuccessAdminAssignedDeveloper, setBtnLoader } = adminDataSlice.actions

export default adminDataSlice.reducer


export function adminListClients(page, payload) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(`admin/clients?page=${page}`, { ...payload })
            if (result.status === 200) {
                toast.success(result?.data.message, { position: "top-center" })
                dispatch(setSuccessAdminListClient(result.data.data.clients))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            if (error?.response?.status === 404) {
                toast.error(error?.response.data.message, { position: "top-center" })
                dispatch(setFailAdminData())
            } else {
                toast.error(message, { position: "top-center" })
                dispatch(setFailAdminData())
            }

        }
    };
}
export function getDeveloperTimeReport(developerId,callback) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.get(`/common/developer-time-reports?developerId=${developerId}`)
            if (result.status === 200) {
                dispatch(setDeveloperTimeReport(result.data?.data[0]))
                callback(result.data?.data[0])
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function getAdminDashboard() {
    return async (dispatch) => {

        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get("admin/dashboard")
            if (result.status === 200) {
                toast.success(result?.data.message, { position: "top-center" })
                dispatch(setAdminDashboard(result.data))
            }
        } catch (error) {
            console.log(error)
            dispatch(setFailAdminData())

        }
    };
}


export function adminListAssignedDeveloper(payload, callback) {
    return async (dispatch) => {
        if(payload?.skill_title=="Select Skills"){
            delete payload.skill_title
        }
        if(payload?.experience_years=="Select Experience"){
            delete payload?.experience_years
        }
        if(payload?.assignment_filter=="Select Developers"){
            delete payload?.assignment_filter
        }


        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(generateApiUrl(payload, "admin/developers"))
            if (result.status === 200) {
                toast.success(result?.data.message, { position: "top-center" })
                dispatch(setSuccessAdminAssignedDeveloper(result.data.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            if (error?.response?.status === 404) {
                toast.error(error?.response.data.message, { position: "top-center" })
                dispatch(setFailAdminData())
            } else {
                toast.error(message, { position: "top-center" })
                dispatch(setFailAdminData())
            }

        }
    };
}


export function getAdminProfile(payload, callback) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get('admin/profile')
            if (result.status === 200) {
                dispatch(setSuccessProfileData(result.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}
export function updateAdminProfile(payload, callback) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.post('admin/update-profile/', { ...payload })
            if (result.status === 200) {
                toast.success("Profile is Updated Successfully", { position: "top-center" })
                dispatch(setSuccessAdminData())
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function getSingleClient(id) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(`common/client-details/${id}`)
            if (result.status === 200) {
                dispatch(setSingleClient(result?.data?.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            dispatch(setFailAdminData())
        }
    };
}



export function adminJobListing(payload, callback) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(generateApiUrl(payload, `admin/job-list`))
            if (result.status === 200) {
                // toast.success("Profile is Updated Successfully", { position: "top-center" })
                dispatch(setSuccessAdminJobListing(result.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function adminSingleJob(payload, callback) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(`admin/job-detail/${payload}`)
            if (result.status === 200) {
                dispatch(setSingleJobListing(result.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function adminTimeReporting(payload) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        let result;
        try {
            if (payload) {
                result = await clientInstance.get((`admin/time-reports?client_id=${payload}`))
            } else {
                result = await clientInstance.get((`admin/time-reports`))
            }
            // if (result.status === 200) {
            // toast.success("Profile is Updated Successful ly", { position: "top-center" })
            dispatch(setAdminTimeReporting(result.data.data))
            dispatch(setAdminClientList(result.data.client_list))
            // }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function allApplicationsList(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(generateApiUrl(payload, `admin/applications`))
            if (result.status === 200) {
                // toast.success("Profile is Updated Successful ly", { position: "top-center" })
                dispatch(setSuccessApplicationList(result.data.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function allMemberList(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(generateApiUrl(payload, `admin/members`))
            if (result.status === 200) {
                // toast.success("Profile is Updated Successful ly", { position: "top-center" })
                dispatch(setSuccessApplicationList(result.data.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function adminEngagementList(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(generateApiUrl(payload, `admin/engagements`))
            if (result.status === 200) {
                // toast.success("Profile is Updated Successful ly", { position: "top-center" })
                dispatch(setAdminEngagment(result.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}
export function adminApproveReject(payload) {
    return async (dispatch) => {
        dispatch(setApprovedLoader())
        try {
            let result = await clientInstance.post(`admin/approve-or-reject-account`, { ...payload })
            if (result.status === 200) {
                toast.success(result.data?.message, { position: "top-center" })
                dispatch(setApproveReject(result.data.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function getDeveloperSuggestList(payload, page) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(`admin/developers-to-suggest/${payload}?page=${page}`)
            if (result.status === 200) {

                dispatch(setSuggestedDeveloper(result.data.data))
                dispatch(setPagination(result.data.pagination))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function suggestDeveloper(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.post(`admin/suggest-developer`, { ...payload })
            dispatch(setSuccessAdminData())
            if (result.status === 200) {
                toast.success(result.data?.message ? result.data?.message : result?.message, { position: "top-center" })
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function editTimeReporting(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.put(`admin/edit-time-report`, { ...payload })
            if (result.status === 200) {
                toast.success(result.data?.message ? result.data?.message : result?.message, { position: "top-center" })
                dispatch(setSuccessAdminData())
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function getNotification(payload) {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get(generateApiUrl(payload, `common/notifications`))
            if (result.status === 200) {
                dispatch(setNotificationList(result.data))
            }
        } catch (error) {
            const message =  error?.response?.data?.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function markAsRead(payload, callback) {
    return async (dispatch) => {
        // dispatch(setScreenLoader())
        try {
            let result
            if (payload == undefined) {
                result = await clientInstance.put(`common/notifications/mark-as-read`)
            } else {
                result = await clientInstance.put(`common/notifications/mark-as-read?notificationId=${payload}`)

            }
            if (result.status === 200) {
                // toast.success(result.data?.message ?result.data?.message:result?.message,  { position: "top-center" })
                return callback()
                // dispatch(setNotificationList(result.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}
export function getAccountEnableDisable() {
    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.get("admin/users-list?page=1")
            if (result.status === 200) {
              dispatch(setAccountEnableDisable(result.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}
export function getAccountDisableEnable(payload) {

    return async (dispatch) => {
        dispatch(setScreenLoader())
        try {
            let result = await clientInstance.post(`/common/enable-disable-user`,{...payload})
            dispatch(setSuccessAdminData())
            toast.success(result?.data?.message ? result.data?.message : result?.message, { position: "top-center" })
        } catch (error) {
            console.log(error,"errrrr")
        }
    };
}

export function createFaq(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.post("admin/create-faqs",{...payload})
            if (result.status === 200) {
                toast.success("Question has been added")
                dispatch(setSuccessAdminData())
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function deleteFaq(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.delete(`admin/delete-faq/${payload}`)
            if (result.status === 200) {
                toast.success("Question has been deleted", { position: "top-center" })
              dispatch(setSuccessAdminData())
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function sendRemarkOnTimeReport(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.post(`common/add-time-report-remark`,{...payload})
                toast.success("Remark has been added", { position: "top-center" })
              dispatch(setSuccessAdminData())
            
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function approvedEditAction(payload) {
    console.log(payload,"payload")
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.put(`/admin/approve-all-changes/${payload}`)
            if (result.status === 200) {
                toast.success("Edit Request has been approved", { position: "top-center" })
              dispatch(setSuccessAdminData())
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function getTimeReportsDetails  (clientId,query){
    return async(dispatch) => {
        dispatch(setScreenLoader());
        try{
            let result = await clientInstance.get(`/admin/clients/${clientId}?${query}`)
            if(result.status === 200){
                dispatch(setTimeReportDetails(result?.data?.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }

    }
}

export function getInvoiceDetails (query) {
    return async(dispatch) => {
        dispatch(setScreenLoader());
        try{
            let result = await clientInstance.get(`/admin/invoices/?${query}`)
            if(result.status === 200){
                dispatch(setInvoiceDetails(result?.data?.data))
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }

    }
}

export function rejectEditAction(payload) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.delete(`/admin/reject-all-changes/${payload}`)
            if (result.status === 200) {
                toast.success("Edit Request has been approved", { position: "top-center" })
              dispatch(setSuccessAdminData())
            }
        } catch (error) {
            const message = error.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}
export function addToFeature(query,closeModal,data,toastMessage) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.put(`/admin/set-featured-and-trusted/${query}`)
            if (result.status === 200) {
                toast.success(toastMessage, { position: "top-center" })
                closeModal();
                dispatch(setSuccessAdminData())
                dispatch(allMemberList(data));
            }
        } catch (error) {
            const message = error.message || "Something went wrong"
            toast.error(message, { position: "top-center" })
            closeModal();
            dispatch(setFailAdminData())
        }
    };
}

export function sendMailForCompleteProfile(payload,data) {
    return async (dispatch) => {
        dispatch(setBtnLoader())
        try {
            let result = await clientInstance.post(`/admin/send-reminder`,{...payload})
            if (result.status === 200) {
                toast.success(result.data?.message, { position: "top-center" })
                dispatch(setSuccessAdminData())
                dispatch(allApplicationsList(data))
            }
        } catch (error) {
            const message = error?.response.data.message || "Something went wrong";
            toast.error(message, { position: "top-center" })
            dispatch(setFailAdminData())
        }
    };
}

export function getConfigDetails() {
    return async(dispatch)=>{
        dispatch(setScreenLoader())
        try{
            let result = await clientInstance.get(`/admin/configuration`)
            console.log(result.data,"result")
            if (result.status === 200) {
                toast.success(result.data?.message, { position: "top-center" })
                dispatch(setSuccessAdminData())
                dispatch(setConfigDetails(result.data))
            }
        }catch(error){
            // const message = error?.response.data.message || "Something went wrong";
            // toast.error(message, { position: "top-center" })
            // dispatch(setFailAdminData())
            console.log(error,"configgerror")

        }
    }
}

export function getUploadFile(payload){
    return async (dispatch)=>{
        try{
            let result = await clientInstance.patch(`admin/configuration` , {...payload})
            console.log(result.data,"result")
            if (result.status === 200) {
                toast.success(result.data?.message, { position: "top-center" })
                dispatch(setSuccessAdminData())
            }
        }catch(error){
            // const message = error?.response.data.message || "Something went wrong";
            // toast.error(message, { position: "top-center" })
            // dispatch(setFailAdminData())

            console.log(error,"error")
        }
    }
}