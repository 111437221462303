import React from 'react'
import JobPostStepContainer from '../../components/common/JobPostForm/JobPostStepContainer'

const AdminJobPost = () => {
  return (
    <>
    <JobPostStepContainer/>
    </>
  )
}

export default AdminJobPost