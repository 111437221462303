import React from 'react'
import JobListing from '../../components/common/JobListing/JobListing'
import JobTabs from '../../components/atomic/JobTabs'

const DeveloperJobListing = () => {
  return (
    <>
    <JobTabs/>
    </>
  )
}

export default DeveloperJobListing